// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  copyParamsList () {
    return {'old': [ 'create_from_name'], 'new': [ 'name']}
  },
  copy (config, {name_new, create_from_name_old}) {
    const params = { 'new': { 'name': name_new}, 'old': { 'create_from_name': create_from_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd/copy`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  createParamsList () {
    return {'old': [ ], 'new': [ 'name', 'categ', 'seclvl', 'description', 'dhcp_domain', 'dhcp_enabled', 'dns_search_list', 'admin_description', 'dhcp_opt_t1_percent', 'dhcp_opt_t2_percent', 'dhcp_ttl_value_percent', 'dhcp_leasetime_dyn_days', 'dhcp_leasetime_dyn_hours', 'dhcp_leasetime_dyn_minutes', 'dhcp_leasetime_static_days', 'dhcp_leasetime_static_hours', 'dhcp_offer_rsv_time_seconds', 'dhcp_leasetime_static_minutes', 'dhcp_dynamic_lease_removal_time', 'dhcp_new_leases_get_static_addr', 'dhcp_new_leases_get_static_fqdn', 'dhcp_new_leases_get_dynamic_addr']}
  },
  create (config, {name_new, categ_new, seclvl_new, description_new, dhcp_domain_new, dhcp_enabled_new = false, dns_search_list_new, admin_description_new, dhcp_opt_t1_percent_new = 50, dhcp_opt_t2_percent_new = 88, dhcp_ttl_value_percent_new = 60, dhcp_leasetime_dyn_days_new = 0, dhcp_leasetime_dyn_hours_new = 2, dhcp_leasetime_dyn_minutes_new = 0, dhcp_leasetime_static_days_new = 0, dhcp_leasetime_static_hours_new = 6, dhcp_offer_rsv_time_seconds_new = 15, dhcp_leasetime_static_minutes_new = 0, dhcp_dynamic_lease_removal_time_new = '1 day', dhcp_new_leases_get_static_addr_new = true, dhcp_new_leases_get_static_fqdn_new = false, dhcp_new_leases_get_dynamic_addr_new = false}) {
    const params = { 'new': { 'name': name_new, 'categ': categ_new, 'seclvl': seclvl_new, 'description': description_new, 'dhcp_domain': dhcp_domain_new, 'dhcp_enabled': dhcp_enabled_new, 'dns_search_list': dns_search_list_new, 'admin_description': admin_description_new, 'dhcp_opt_t1_percent': dhcp_opt_t1_percent_new, 'dhcp_opt_t2_percent': dhcp_opt_t2_percent_new, 'dhcp_ttl_value_percent': dhcp_ttl_value_percent_new, 'dhcp_leasetime_dyn_days': dhcp_leasetime_dyn_days_new, 'dhcp_leasetime_dyn_hours': dhcp_leasetime_dyn_hours_new, 'dhcp_leasetime_dyn_minutes': dhcp_leasetime_dyn_minutes_new, 'dhcp_leasetime_static_days': dhcp_leasetime_static_days_new, 'dhcp_leasetime_static_hours': dhcp_leasetime_static_hours_new, 'dhcp_offer_rsv_time_seconds': dhcp_offer_rsv_time_seconds_new, 'dhcp_leasetime_static_minutes': dhcp_leasetime_static_minutes_new, 'dhcp_dynamic_lease_removal_time': dhcp_dynamic_lease_removal_time_new, 'dhcp_new_leases_get_static_addr': dhcp_new_leases_get_static_addr_new, 'dhcp_new_leases_get_static_fqdn': dhcp_new_leases_get_static_fqdn_new, 'dhcp_new_leases_get_dynamic_addr': dhcp_new_leases_get_dynamic_addr_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'name'], 'new': [ ]}
  },
  delete (config, {name_old}) {
    const params = { 'new': { }, 'old': { 'name': name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, name, is_own, gpk_list, name_list, fetch_limit, name_regexp, dhcp_enabled, fetch_offset, filter_params_dict, sorting_params_list, dhcp_new_leases_get_static_addr, dhcp_new_leases_get_static_fqdn, dhcp_new_leases_get_dynamic_addr }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'name': name, 'is_own': is_own, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'name_list': (name_list === null) ? null : JSON.stringify(name_list), 'fetch_limit': fetch_limit, 'name_regexp': name_regexp, 'dhcp_enabled': dhcp_enabled, 'fetch_offset': fetch_offset, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list), 'dhcp_new_leases_get_static_addr': dhcp_new_leases_get_static_addr, 'dhcp_new_leases_get_static_fqdn': dhcp_new_leases_get_static_fqdn, 'dhcp_new_leases_get_dynamic_addr': dhcp_new_leases_get_dynamic_addr})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'name'], 'new': [ 'name', 'categ', 'seclvl', 'description', 'dhcp_domain', 'dhcp_enabled', 'dns_search_list', 'admin_description', 'dhcp_opt_t1_percent', 'dhcp_opt_t2_percent', 'dhcp_ttl_value_percent', 'dhcp_leasetime_dyn_days', 'dhcp_leasetime_dyn_hours', 'dhcp_leasetime_dyn_minutes', 'dhcp_leasetime_static_days', 'dhcp_leasetime_static_hours', 'dhcp_offer_rsv_time_seconds', 'dhcp_leasetime_static_minutes', 'dhcp_dynamic_lease_removal_time', 'dhcp_new_leases_get_static_addr', 'dhcp_new_leases_get_static_fqdn', 'dhcp_new_leases_get_dynamic_addr']}
  },
  update (config, {name_old, name_new, categ_new, seclvl_new, description_new, dhcp_domain_new, dhcp_enabled_new, dns_search_list_new, admin_description_new, dhcp_opt_t1_percent_new, dhcp_opt_t2_percent_new, dhcp_ttl_value_percent_new, dhcp_leasetime_dyn_days_new, dhcp_leasetime_dyn_hours_new, dhcp_leasetime_dyn_minutes_new, dhcp_leasetime_static_days_new, dhcp_leasetime_static_hours_new, dhcp_offer_rsv_time_seconds_new, dhcp_leasetime_static_minutes_new, dhcp_dynamic_lease_removal_time_new, dhcp_new_leases_get_static_addr_new, dhcp_new_leases_get_static_fqdn_new, dhcp_new_leases_get_dynamic_addr_new}) {
    const params = { 'new': { 'name': name_new, 'categ': categ_new, 'seclvl': seclvl_new, 'description': description_new, 'dhcp_domain': dhcp_domain_new, 'dhcp_enabled': dhcp_enabled_new, 'dns_search_list': dns_search_list_new, 'admin_description': admin_description_new, 'dhcp_opt_t1_percent': dhcp_opt_t1_percent_new, 'dhcp_opt_t2_percent': dhcp_opt_t2_percent_new, 'dhcp_ttl_value_percent': dhcp_ttl_value_percent_new, 'dhcp_leasetime_dyn_days': dhcp_leasetime_dyn_days_new, 'dhcp_leasetime_dyn_hours': dhcp_leasetime_dyn_hours_new, 'dhcp_leasetime_dyn_minutes': dhcp_leasetime_dyn_minutes_new, 'dhcp_leasetime_static_days': dhcp_leasetime_static_days_new, 'dhcp_leasetime_static_hours': dhcp_leasetime_static_hours_new, 'dhcp_offer_rsv_time_seconds': dhcp_offer_rsv_time_seconds_new, 'dhcp_leasetime_static_minutes': dhcp_leasetime_static_minutes_new, 'dhcp_dynamic_lease_removal_time': dhcp_dynamic_lease_removal_time_new, 'dhcp_new_leases_get_static_addr': dhcp_new_leases_get_static_addr_new, 'dhcp_new_leases_get_static_fqdn': dhcp_new_leases_get_static_fqdn_new, 'dhcp_new_leases_get_dynamic_addr': dhcp_new_leases_get_dynamic_addr_new}, 'old': { 'name': name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/nd/bcd/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
